.custom-drop-down {
  width: 155px;
  height: 30px;
  display: inline-block;
  margin-right: 7px;
  margin-left: 7px;

}
.custom-drop-down-control-class-name {
  background-color: black !important;
  border-color: #e63731 !important;
  width: 155px;
  height: 30px;
  line-height: 0.8;
  color: #e63731 !important;
}
.custom-dropdown-option {
  color: #e63731 !important;
  background-color: black;
}
.big .Dropdown-control{
  width: 350px !important;
}
